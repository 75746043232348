<template>
    <div>
        <div class="crumbs">

            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/renew' }">續費管理</el-breadcrumb-item>
                <el-breadcrumb-item>續費紀錄</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <el-row class="title">
                <el-col :span="24">
                    <h2>續費紀錄
                    </h2>
                </el-col>
            </el-row>
            <el-tabs v-model="activeName"
                     type="card"
                     @tab-click="changeTabs">
                <el-tab-pane label="自動續費變更紀錄"
                             name="automatic"></el-tab-pane>
            </el-tabs>
            <el-form ref="formRenew"
                     :inline="true"
                     :model="formInline"
                     class="domain-form-inline"
                     @submit.prevent>
                <el-form-item label="域名:"
                              prop="domain">
                    <el-input v-model="formInline.domain"
                              placeholder="請輸入域名"></el-input>
                </el-form-item>
                <el-form-item label="自動續費變更日期:">
                    <el-date-picker v-model="formInline.datetime"
                                    type="daterange"
                                    :shortcuts="shortcuts"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary"
                               @click="handleSearch">查询</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button @click="resetForm">清除</el-button>
                </el-form-item>
            </el-form>
            <el-form :inline="true"
                     class="domain-form-inline">
                <el-form-item>
                    <el-button v-if="multipleSelection.length>0"
                               @click="exportCSV">匯出CSV</el-button>
                    <el-button v-else
                               plain
                               disabled>匯出CSV</el-button>
                </el-form-item>
                <el-form-item>
                    <div>共勾選 <span class="span-color-primary">{{totalChecked}}</span> 筆, 總共 <span class="span-color-primary">{{totalCount}}</span> 筆</div>
                </el-form-item>
            </el-form>
            <el-table :data="tableData"
                      @selection-change="handleSelectionChange"
                      ref="tableref"
                      v-loading="listLoading"
                      :row-key="getRowKey"
                      border
                      class="table">
                <el-table-column type="selection"
                                 :reserve-selection="true"
                                 :selectable="isDisabled">

                </el-table-column>
                <el-table-column prop="domain"
                                 label="域名"
                                 align="center" />
                <el-table-column prop="created_at"
                                 width="180"
                                 label="自動續費變更日期"
                                 align="center" />
                <el-table-column label="預期變更"
                                 align="center">
                    <template #default="scope">
                        <div v-if="scope.row.expect_result=='Y'">自動續費</div>
                        <div v-else>取消自動續費</div>
                    </template>
                </el-table-column>
                <el-table-column label="變更結果"
                                 align="center">
                    <template #default="scope">
                        <div v-if="scope.row.renew_result=='Y'">成功</div>
                        <div v-else>失敗</div>
                    </template>
                </el-table-column>
                <el-table-column label="續約狀態"
                                 align="center">
                    <template #default="scope">
                        <div v-if="scope.row.status=='COMPLETE'">完成</div>
                        <div v-if="scope.row.status=='IN_PROCESSING'">處理中</div>
                    </template>
                </el-table-column>
                <el-table-column prop="domain_expires_at"
                                 label="到期續費日"
                                 align="center" />
                <el-table-column prop="editor"
                                 label="操作"
                                 align="center" />

            </el-table>
            <!-- 分頁 -->
            <div class="pagination">
                <el-pagination background
                               layout=" prev, pager, next"
                               :current-page="pageIndex"
                               :page-count="pageCount"
                               @current-change="handlePageChange">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import { automaticRenewList, automaticRenewListExport } from '../../../api/index'
import { dateFormat } from '@/utils/dateFormat.js'
export default {
    data() {
        return {
            formInline: {},
            tableData: [], //域名列表
            detailList: [], //詳細資訊列表
            detailInfo: [], //詳細資訊
            pageIndex: 1,
            pageCount: 0,
            multipleSelection: [], //打勾的域名列表
            activeName: 'automatic', //標籤頁
            remarkList: {},
            editVisible: false, //燈窗顯示與否
            totalChecked: 0, //共勾選?
            totalCount: 0, //共幾筆?
            oreder: '', //排序用
            test: 1,
            listLoading: false, //列表用
            shortcuts: [
                {
                    text: '1日',
                    value: (() => {
                        const end = new Date()
                        const start = new Date()
                        end.setTime(end.getTime() + 3600 * 1000 * 24 * 1)
                        return [start, end]
                    })(),
                },
                {
                    text: '7日',
                    value: (() => {
                        const end = new Date()
                        const start = new Date()
                        end.setTime(end.getTime() + 3600 * 1000 * 24 * 7)
                        return [start, end]
                    })(),
                },
                {
                    text: '30日',
                    value: (() => {
                        const end = new Date()
                        const start = new Date()
                        end.setTime(end.getTime() + 3600 * 1000 * 24 * 30)
                        return [start, end]
                    })(),
                },
            ],
        }
    },
    computed: {
        startDay() {
            if (this.formInline.datetime) {
                return dateFormat(this.formInline.datetime[0])
            }
            return ''
        },
        endDay() {
            if (this.formInline.datetime) {
                return dateFormat(this.formInline.datetime[1])
            }
            return ''
        },
    },
    mounted() {
        if (typeof this.$route.query.activeName == 'undefined') {
            this.activeName = 'automatic'
        } else {
            this.activeName = this.$route.query.activeName
        }
        this.getData()
    },
    methods: {
        getData() {
            let data = {
                domain: this.formInline.domain,
                page: this.pageIndex,
                created_at: this.startDay,
                created_end: this.endDay,
            }
            this.getAutomaticRenewList(data)
        },
        getAutomaticRenewList(data) {
            this.listLoading = true
            automaticRenewList(data)
                .then((res) => {
                    if (res.code == 300) {
                        this.tableData = res.data.data_list
                        this.totalCount = res.data.total_count
                        this.pageCount = res.data.total_page
                    } else if (res.code == 500) {
                        this.$message.error(res.message)
                    }
                    this.listLoading = false
                })
                .catch((err) => {
                    this.$message.error(err)
                    this.listLoading = false
                })
        },
        toPage(page) {
            this.$router.push({ path: page })
        },
        //查詢
        handleSearch() {
            this.getData()
        },
        //清除
        resetForm() {
            this.formInline = []
        },
        //勾選
        handleSelectionChange(val) {
            this.multipleSelection = []
            this.totalChecked = 0
            val.forEach((element) => {
                this.totalChecked++
                this.multipleSelection.push(element.id)
            })
        },

        // 分页导航
        handlePageChange(val) {
            this.pageIndex = val
            this.getData()
        },
        //切換tabs
        changeTabs() {
            this.pageIndex = 1
            this.formInline = []
            this.$refs.tableref.clearSelection()
            this.getData()
        },
        getRowKey(row) {
            return row.id
        },
        //可不可以勾選
        isDisabled(row) {
            if (row.id === '1') {
                return 0
            }
            return 1
        },
        //匯出csv
        exportCSV() {
            this.automaticExport()
        },
        //自動續費CSV API
        automaticExport() {
            let data = {
                setting_history_id: this.multipleSelection,
            }
            automaticRenewListExport(data).then((res) => {
                const csv = 'application/vnd.ms-excel'
                const blob = new Blob([res], { type: csv })
                const a = document.createElement('a') // 轉換完成，建立一個a標籤用於下載
                a.download = `${this.$t('自動續費變更紀錄')}.csv`
                a.href = window.URL.createObjectURL(blob)
                a.click()
                a.remove()
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.title {
    padding-bottom: 20px;
}
.add-btn {
    padding-bottom: 15px;
    align-items: center;
}
.domain-form-inline {
    padding-top: 20px;
}
.table {
    width: 100%;
    font-size: 14px;
}
.span-color-primary {
    color: #409eff;
}
.span-color-danger {
    color: #ff0000;
}
</style>
<style>
.el-table__header col[name='gutter'] {
    display: table-cell !important;
}
.el-table__body {
    width: 100% !important ;
}
.renewRecord-dialog .el-dialog__body {
    padding-top: 0 !important;
}
</style>